import { getApps, initializeApp } from "firebase/app";
import {
  Messaging,
  getMessaging,
  getToken,
  isSupported,
} from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyDkFFc8QbW9uUi8_AfXJqrMWjn43tCNJjM",
  authDomain: "pikgo-d391a.firebaseapp.com",
  projectId: "pikgo-d391a",
  storageBucket: "pikgo-d391a.firebasestorage.app",
  messagingSenderId: "601034856152",
  appId: "1:601034856152:web:de4a041b2c746bf7d4a9ad",
  measurementId: "G-8PVLG0020H"
};

// Initialize Firebase
export const app = !getApps()?.length
  ? initializeApp(firebaseConfig)
  : getApps()[0];

// Initialize Firebase Cloud Messaging and get a reference to the service
export const getMessagingObj = async () => {
  const supported = await isSupported();
  console.log("is supported fcm? >>", supported);
  if (!supported || typeof window === "undefined") return null;
  return getMessaging(app);
};

export const fetchToken = async () => {
  try {
    const messaging = await getMessagingObj();
    if (messaging) {
      console.log(messaging);
      const token = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_VAPID_KEY ?? "",
      });
      return token;
    }
    return null;
  } catch (err) {
    console.error(err);
    return null;
  }
};

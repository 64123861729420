import { useCallback, useEffect, useRef, useState } from "react";
import { fetchToken, getMessagingObj } from "./firebase";
import { onMessage } from "firebase/messaging";
import { toast } from "react-toastify";
import axiosInstance from "../HelperAxios/axios";

async function notifyMe() {
  if (!("Notification" in window)) {
    // Check if the browser supports notifications
    console.info("This browser does not support desktop notification");
  } else if (Notification.permission === "granted") {
    // Check whether notification permissions have already been granted;
    // if so, create a notification
    const token = await fetchToken();
    console.log("Notification permission granted.");
    if (!token) {
      return null;
    }
    return token;
  } else if (Notification.permission !== "denied") {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      const token = await fetchToken();
      console.log("Notification permission granted.");
      if (!token) {
        return null;
      }
      return token;
    } else {
      console.log("Notification permission not granted.");
    }
  }
  return null;
}

export const useFCM = () => {
  const [fcmToken, setFcmToken] = useState(null);
  const retryLoadToken = useRef(0);

  const loadToken = async () => {
    const deniedOnce = localStorage.getItem("notification_count");
    console.log(deniedOnce)
    if (!deniedOnce) {
      if (Notification.permission === "denied") {
        toast.warning("Please Enable Push notfications via browser settings.");
        console.info(
          `%cPlease enable notfications via browser settings.`,
          "color: red; background: #c7c7c7; padding: 8px; font-size: 20px"
        );
        return null;
      }
    }

    if (retryLoadToken.current >= 3) {
      console.info(
        `%cPush Notifications issue - unable to load token after 3 retry, you can refresh the browser`,
        "color: green; background: #c7c7c7; padding: 8px; font-size: 20px"
      );
      return null;
    }
    const token = await notifyMe();

    if (!token) {
      retryLoadToken.current += 1;
      loadToken();
      return;
    }

    setFcmToken(token);
  };

  useEffect(() => {
    if (fcmToken) {
      axiosInstance
        .put("/user/update-fcm-token", { fcmToken: fcmToken })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [fcmToken]);

  const listenerMessage = useCallback(async () => {
    if (!fcmToken) return null;
    console.log(`onMessage Reg withFcmToken ${fcmToken}`);
    const messaging = await getMessagingObj();
    if (!messaging) return null;
    return onMessage(messaging, (payload) => {
      if (Notification.permission !== "granted") return;

      console.log("Foreground message loaded >>> payload", payload);
      const data = payload?.notification ?? {};
      // Customize notification here
      const notificationTitle = data.title ?? "";
      const notificationOptions = {
        body: data.body,
        icon: data.icon,
      };

      new Notification(notificationTitle, notificationOptions);
    });
  }, [fcmToken]);

  useEffect(() => {
    if ("Notification in window" && Notification.permission === "granted")
      loadToken();
  }, []);

  useEffect(() => {
    let instanceOnMessage;
    listenerMessage().then((r) => (instanceOnMessage = r));

    return () => instanceOnMessage?.();
  }, [listenerMessage]);

  return { loadToken };
};

import LocalizedStrings from "react-localization";
const strings = new LocalizedStrings({
  fa: {
    ///// questions
    QU1: "Have you collaborated with similar apps before?",
    QU2: "This ticket is approved by the main admin (non-routine tickets must have this tick)",
    RequiredDocument: "Required documents",
    NotFound: "Not found item",

    /// text
    text1: "Deducting membership fee from income (monthly 300,000 Tomans)",
    text: "Text",
    newVersion: "New Version",
    previousVersion: "Old Version",
    Yes: "Yes",
    No: "No",
    int: "number",
    Hour: "Hour",
    Meter: "meter",
    Model: "Model",
    Brand: "Brand",
    RegisterationDate: "Registeration Date",
    BanDriver: "Ban Driver",
    ///test

    /// error
    error1: "Birth year is wrong",
    companyEmpty: "No company",
    error2: "Entered passwords are not the same",
    NoAccess: "You do not have access to this operation.",
    NoUploadingImage: "The photo has not been uploaded.",
    UserNotAccess: "Dear user, you do not have access to this section.",
    RequiredSubjectErr: "Entering a subject is required.",

    //// photos
    photoCertificate: "Certificate",

    /// dashboard
    EntryAndExitLogs: "Entry and exit logs",
    ///login
    ForgetPassword: "Forget Password",
    FleetsRegister: "Fleets Register",

    /// motion
    motionType1: "Photo",
    motionType2: "Motion",
    motionPlace1: "Current trips",
    motionPlace2: "List of drivers",
    motionPlace3: "All",

    /// roles
    role1: "Admin",
    role2: "Car representative",
    role3: "Car owner",
    //default number
    defaultNumber: "+358",

    /// commission
    comUserType1: "Passenger transport company",
    comUserType2: "Car owner",
    comRate1: "Excellent",
    comRate2: "Normal",
    comRate3: "Good",
    comRate4: "Bad",
    comOption1: "Inter-city service",
    comOption2: "Station service",
    comOptionValue1: "Intermediate route",
    comOptionValue2: "City station",
    commissionList: "List of commissions",
    commissions: "Commissions",
    commissionDrivers: "Drivers' commission",
    AddCommissionDrivers: "Add drivers' commission",
    EditCommissionDrivers: "Edit drivers' commission",
    SeeCommissionDrivers: "View drivers' commission",
    SeeButtonDetailDriver: "Details of driver buttons",
    SeeButtonDetailPassenger: "Details of passenger buttons",
    payTrip_time: "Travel cost payment time",
    trip_createdAt: "Travel registration time",
    RegisterTime: "Registration time",
    cancel_data: "Trip cancellation details",
    cancel_reason_data: "Trip cancellation reason",
    change_arrivalTime_dvToPass: "Change in driver's arrival time to passenger",
    ArrivalTime: "Arrival time",
    ArrivalTimeTo: "Remaining time",
    time_entered_by_driver: "Time entered by driver",
    stopTime: "Driver's stop times",
    TimeUpdate: "Update date",
    UpdateStatus: "Edit status",

    /// car owner status
    carOwnerStatus: "Car owner status",
    carOwnerStatusFilter: "Car owner status",
    carOwnerStatus1: "Document submission",
    carOwnerStatus1Filter: "Document%20submission",
    carOwnerStatus2: "Under review",
    carOwnerStatus2Filter: "Under%20review",
    carOwnerStatus3: "Defect correction",
    carOwnerStatus3Filter: "Defect%20correction",
    carOwnerStatus4: "Active",
    carOwnerStatus4Filter: "Active",
    carOwnerStatus5: "Conditional",
    carOwnerStatus5Filter: "Conditional",
    carOwnerStatus6: "Admin%20blocked",
    carOwnerStatus6Filter: "Admin%20blocked",

    carStatus: "Car status",
    carStatus1: "Document submission",
    carStatus2: "Under review",
    carStatus3: "Defect correction",
    carStatus4: "Active",
    carStatus5: "Conditional",
    carStatus6: "System blocked",
    carStatus7: "Admin blocked",
    carStatus8: "Requested blocked",
    NationalCardPicture: "National card picture",
    NotarizedPicture: "Notarized file",
    TotalIncomeCar: "Total car income",

    /// driver
    DriverStatus: "Driver status",
    DriverStatus1: "Document submission",
    DriverStatus2: "Under review",
    DriverStatus3: "Defect correction",
    DriverStatus4: "Active",
    DriverStatus5: "Conditional",
    DriverStatus6: "System blocked",
    DriverStatus7: "Admin blocked",
    DriverStatus8: "Requested blocked",
    DriverStatus9: "No age or license requirement",
    phone: "Phone number",
    showDriverChanges: "Show driver changes",
    DriverChanges: "Driver changes",

    /// car
    DrivingLicenseHistory: "Driving license history",
    Normal: "Normal",
    Good: "Good",
    Perfect: "Excellent",
    Commission: "Driver commission for this car",
    AgentStatus: "Agent status",
    AdminLock: "Admin blocked",
    Sample: "Sample",
    View: "View",
    New: "New",
    Old: "Old",
    /// company
    Service1: "Ride service",
    Service2: "Bus service",
    ServiceFeature1: "Inter-city service",
    ServiceFeature2: "Station service",
    StatusCompany1: "Document submission",
    StatusCompany2: "Under review",
    StatusCompany3: "Defect correction",
    StatusCompany4: "Active",
    StatusCompany5: "Conditional",
    StatusCompany6: "Admin blocked",

    ///useful
    yes: "yes",
    no: "no",

    ///feedBack
    feedBackType1: "Passenger",
    feedBackType2: "Driver",
    feedBackType3: "Transport Company",
    feedBackType4: "Car Owner",
    feedBackType5: "Portal Users",
    feedBackType6: "Role",

    /// driver fine
    minimumCancelingPassengerTrip: "Minimum cancellation fee per passenger trip",
    CancelingPassengerTrip: "Cancellation fee per passenger trip",
    notChoosingPassengerTrip: "No car selection penalty by passenger",
    notAttendOriginPassengerTrip: "Minimum penalty for not attending origin by passenger",
    minimumValidityOrder: "Minimum passenger validity for order registration",
    driverAbsenceInOrigin: "Minimum penalty for driver absence at origin",
    minimumMinimumRate: "Minimum minimum rate",
    maxMinimumRate: "Maximum minimum rate",
    percentTerminal: "Terminal fee percentage",

    /// motion graphic
    statusTrip: "Trip Status",
    subjectAD: "Ad Subject",
    placeAdvertisement: "Ad Location",
    ViewCount: "View Count",
    maxView: "Max View Count",
    // ViewCount: "Max View Count",
    Advertiser: "Advertiser",
    PassengerDestination: "Passenger Destination",
    MotionGraphicsCode: "Motion Graphics Code",
    TypeAdvertisement: "Ad Type",
    /// wallet
    moneyIncreaseWallet: "Increase in Credit",
    increaseWallet: "Increase Credit",
    increaseWalletBadge: "Increase Wallet Balance",
    searchInInfo: "Search in Description",
    transactionCode: "Transaction Code",
    WalletText: "Registering the bank deposit receipt details in the transaction description is mandatory.",
    ///supervisor
    supervisorName: "Supervisor Name",
    referTo: "Refer To",
    subjectViolation: "Violation Subject",
    operatorName: "Operator Name",
    pointPlus: "Positive Points",
    pointMinus: "Negative Points",
    pointDriver: "Driver Points",
    point: "Points",
    pointType: "Point Type",
    operatorPart: "Operator Section",
    operatorAdmin: "Operator Admin",
    ticketNumber: "Ticket Number",
    receiverRole: "Receiver Role",
    senderRole: "Sender Role",
    action: "Action",
    contact: "Contact",
    date: "Date",
    role: "Role",
    registrar: "Registrar",
    SeeTicketsList: "View Tickets List",
    reference: "Reference Number",
    referenceDate: "Reference Date",
    referenceCount: "Reference Count",
    sign: "Sign",
    title: "Title",
    SubTitle: "Subtitle",
    comment: "Comment",
    audienceSide: "Audience Role",
    contactName: "Contact Name",
    driversCounts: "Driver Count",
    TotalGiftCredit: "Total Gift Credit",
    passengerFare: "Passenger Fare",
    Fare: "Fare",
    passengerPoint: "Passenger Points",
    giftCredit: "Gift Credit",
    driverCode: "Driver Code",
    startCity: "Starting City",
    endCity: "Destination City",
    CityCount: "City Count",
    cost: "Cost",
    Cost: "Cost",
    operator: "Operator",
    travelRegistrationDate: "Travel Registration Date",
    passengerNumber: "Passenger Number",
    carOwner: "Car Owner",
    carOwnerPhone: "Car Owner Phone",
    driverNumber: "Driver Number",
    number: "Number",
    count: "Count",
    wayfarer: "Wayfarer",
    totalIncome: "Total Income",
    totalIncome2: "Total Fare",
    emptyChair: "Empty Chair",
    ID: "ID",
    carCount: "Car Count",
    travelCount: "Travel Count",
    TimeLastTrip: "Last Trip Time",
    CompanyName: "Company Name",
    CompanyStatus: "Company Status",
    Rank: "Rank",
    Rate: "Score",
    LicenseExperienceDuration: "License Experience Duration",
    BusinessIdentityCode: "Business identity code",
    cooperationStartDate: "Cooperation Start Date",
    TaxNumber: "Tax Number",
    TaxiOperatingLicenseExpirationDate: "Taxi operating license expiration date",
    IdCard_Passport: "ID Card / Passport",
    IdCardExpirationDate: "ID card expiration date",
    TaxiDriversLicense: "Taxi driver’s license",
    TaxiDriversLicenseExpirationDate: "Taxi driver’s license expiration date",
    VehiclePhoto: "Vehicle Photo",
    TaxiPointCertificate: "Taxi point certificate",
    RegisterStartDate: "Registration Start Date",
    all: "All",
    // page titles
    productsList: "Products List",
    TextContent: "Content",
    ForDrivers: "For Drivers",
    manageUsers: "User Management",
    areaList: "Area List",
    payMethodList: "Payment Methods",
    statusList: "Status List",
    statuses: "Statuses",
    subGroup: "Subgroup List",
    orders: "Orders",
    DynamicItems: "Dynamic Items",
    Driverscommission: "Commission List of Drivers",
    ordersList: "Orders List",
    everydayAccess: "Everyday Access",
    seeOrder: "View Order",
    Reports: "Reports",
    ReportsFleet: "Reports",
    Tickets: "Tickets",
    Compensations: "Compensations",
    TollRoads: "Toll Roads",
    CancellationFees: "Cancellation Fees",
    Expenses: "Expenses",
    Invoices: "Invoices",
    Balance: "Balance",
    SettingFleet: "Setting",
    ExpiringDocuments: "Expiring Documents",
    LiveMap: "Live map ",
    FleetToRider: "Fleet To Rider",
    PikgoToFleet: "Pikgo To Fleet",
    RefundsToRiders: "Refunds To Riders",
    BookingFees: "Booking Fees",
    TotalExpenses: "Total Expenses",
    CommissionExpenses: "Commission Expenses",
    TicketCategoriesList: "List of Ticket Subjects",
    AddTicketCategory: "Add Subject",
    EditTicketCategory: "Edit Subject",
    SeeTicketCategory: "View Subject",
    CategoryName: "Subject Name",
    TicketCategoryField1: "Expiration Time in Emergency Priority",
    TicketCategoryField2: "Expiration Time in Very Important Priority",
    TicketCategoryField3: "Expiration Time in Important Priority",
    Minute: "Minute",
    TicketsList: "List Tickets",
    Write1: "Copy 1",
    Write2: "Copy 2",
    Write3: "Copy 3",
    SeeTicket: "View",
    DriverDetailsInNowTrip: "Driver Status in Current Trip",
    Archive: "Archive",
    Support: "Support",
    InternalTicket: "Internal Tickets",
    InternalUsers: "Internal Users",
    SuperintendentsReport: "Supervisor Report",
    AddSuperintendentsReport: "Add Supervisor Report for Operators",
    EditSuperintendentsReport: "Edit Supervisor Report for Operators",
    SeeSuperintendentsReport: "View Supervisor Report for Operators",
    SuperintendentsTicketReport: "Supervisor Report (Tickets)",
    ReportOfOprators: "Supervisor Report for Operators",
    PointsAndAwards: "Points and Rewards",
    CarOwnerAccess: "Car Owner Access",
    MonthlyDriverPoints: "Monthly App Rating for Drivers",
    SixMonthlyDriverPoints: "Six-Month App Rating for Drivers",
    YearlyDriverPoints: "Annual App Rating for Drivers",
    LastYearPoint: "Last Year's Rank",
    SeasonalyPoint: "Seasonal Rank",
    MonthlyPassengerPoints: "Monthly App Rating for Passengers",
    YearlyPassengerPoints: "Annual App Rating for Passengers",
    MonthlyGift: "Monthly Gift Credit for Passengers and Drivers",
    YearlyGift: "Annual Gift Credit for Passengers and Drivers",
    PassengerOnMap: "Travels on the Map",
    DriverOnMap: "Drivers on the Map",
    AdminMap: "Admin Map",
    FleetMap: "Fleet Map",
    TripsOnMap: "Trips on the Map",
    cityFromDriver: "Driver's Travel City",
    ModalName: "Modal Name",
    StartTime: "StartTime",
    Auto: "Auto",
    DiscountType: "Discount Type",
    Discount: "Discount",
    voucher: "voucher",
    Booked: "Booked",
    tripFare: "trip Fare",
    ListDiscount: "List Of Discount",
    cityToDriver: "Driver's Destination City",
    SpecialRouteReport: "Special Route Report",
    RequestListOfSelectedTrips: "Selected Trips Request List",
    FincaneReport: "Financial Reports",
    MonthlyExpenseReport: "Monthly Expense Report",
    MonthlyIncomeReport: "Monthly Income Report",
    Microtransactions: "transactions",
    MyMicrotransactions: "My Transaction Details",
    MicrotransactionsAll: "General Transaction Details List",
    AddMicrotransactions: "Add transaction",
    DescribeTransactions: "Transaction Description",
    MicrotransactionsMonthly: "Monthly transactions",
    AccessRegisterCar: "Car Registration Access",
    MonthlyMicrotransactions: "Monthly transactions",
    RegisterAndEditAgent: "Register/Edit Representative",
    Registers: "Registers",
    SupportsRequest: "Support Requests",
    MessageAndNoti: "Messages and Notifications",
    SendOneNotification: "Send Notification",
    SendGroupNoti: "Send Group Notification",
    ListOfMotionGraphics: "Motion Graphics List",
    AddOfMotionGraphics: "Add Motion Graphic",
    EditOfMotionGraphics: "Edit Motion Graphic",
    ShowOfMotionGraphics: "View Motion Graphic",
    DriverAndPassengerFines: "Driver and Passenger Fines",
    numberOfComplaintsbyGroup: "Number of Complaints by Group Leader",
    reportCorrectiveActions: "Corrective Actions Report",
    reportTheNumberOfComplaintsBySalesExpert: "Number of Complaints by Sales Expert",
    customerFeedback: "Customer Feedback List",
    reportTheNumberOfComplaintsByCompanyExpert: "Number of Complaints by Companies - CRM",
    OpenningGroupAccounts: "Opening Group Leader Accounts",
    DefineSalesTarget: "Define Sales Target",
    GroupCumulativeSalesChart: "Group Cumulative Sales Chart",
    ReportPercentageOfCustomerSatisfactionWithTheGrievanceProcess: "Report - Customer Satisfaction Percentage with Complaint Process",
    TheLatestStatusOfComplaintsAtTheGroupLevel: "Latest Status of Complaints at Group Level",
    GraphTheNumberOfComplaintsByCompanies: "Number of Complaints by Companies - CRM Chart",
    AverageInitialResponseReport: "Average Initial Response Time Report",
    averageExternalClosureTime: "Average External Closure Time Report",
    AverageInternalColuseTime: "Average Internal Closure Time Report",
    NumberOfComplaintsByCompanies: "Feedback by Company",
    ProductDuringOrdering: "Product in Order Process",
    ReportTheNumberOfComplaintsBySolutionCategory: "Number of Complaints by Solution Category Report",
    SalesChartsBySalesManagers: "Sales by Sales Managers Chart",
    SalesTargetChartReport: "Sales Target Chart Report",
    ManageProductComponents: "Manage Product Components",
    CreateUser: "Add User",
    AddDiversToCar: "Add Driver to Car",
    UpdateUser: "Edit User",
    SeeUser: "View User",
    CreateProduct: "Add Product",
    IncreaseWallet: "Increase Wallet Balance",
    CreateCompany: "Add Company",
    UpdateProduct: "Edit Product",
    SeeProduct: "View Product",
    UpdateCompany: "Edit Company",
    ReportSalesByArea: "Sales by Area Report",
    TargetSalesGroupReport: "Cumulative Sales Target Report",
    SepretSalesReport: "Detailed Sales Report",
    SalesTargetReport: "Sales Target Report",
    SalesVolumeByCustomerSeparate: "Sales Volume by Customer",
    ReportSalesExpertPercentage: "Sales Expert Percentage Chart Report",
    Comments: "Comments",
    Activities: "Activities",
    LastActivities: "Last Activities",
    Home: "Dashboard",
    GroupSalesReport: "Cumulative Sales Report",
    FactorySaleReport: "Companies Sales Report",
    remittancePriceRange: "Price Range by Remittance",
    ReportComulativeSale: "Cumulative Sales Report",
    AccessDriver: "Driver Access",
    ListOFGroupHeading: "Group Leaders List",
    CreateRealGroupHeader: "Add Real Group Leader",
    UpdateRealGroupHeader: "Edit Real Group Leader",
    SeeRealGroupHeader: "View Real Group Leader",
    addALegalGroup: "Add Legal Group Leader",
    UpdateALegalGroup: "Edit Legal Group Leader",
    SeeALegalGroup: "View Legal Group Leader",
    NumberOfComplainDashboard: "Feedback Dashboard",
    NotificationList: "Notification List",
    DiagramReportOfCustomerPurchaseProcess: "Customer Purchase Process/Time Chart Report",
    ManageUsersGroups: "User Groups List",
    UpdateUserGroup: "Edit User Group",
    CreateUserGroup: "Add User Group",
    FinancialManagerAccessLevel: "Financial Manager Access Level",
    DriversAccessList: "Drivers Access List",
    AddALegalBuyer: "Add Legal Buyer",
    SeeALegalBuyer: "View Legal Buyer",
    SeeARealBuyer: "View Real Buyer",
    EditALegalBuyer: "Edit Legal Buyer",
    EditARealBuyer: "Edit Real Buyer",
    DefineSalesTargetList: "Define Sales Target",
    SalesTargetList: "Sales Target List",
    CreateaSaleTarget: "Add Sales Target",
    UpdateaSaleTarget: "Edit Sales Target",
    ReportByComplaintSubject: "Report by Complaint Subject",
    SubjectRequest: "Request Subject",
    Creator: "Creator",
    MainSettings: "Main Settings",
    FollowUpAndResponseForm: "Follow-Up and Response Form",
    SurveyQuestionnaire: "Survey Form",
    FeedBackForm: "Feedback Form",
    CorrectiveActionForm: "Corrective Action Form",
    SendProducts: "Send Products",
    SeeProducts: "View Products",
    //   sidebar
    mainMenu: "Main Menu",
    coding: "Coding",
    manageUser: "User Management",
    userGroups: "User Groups",
    products: "Products",
    manageProductComponent: "Product Components",
    areas: "Area List",
    paymentMethod: "Payment Method",
    TravelUsersList: "Passenger Travels List",
    Headquarters: "Headquarters",
    buyers: "Buyers",
    DashboardSafetyShield: "Safety Shield Dashboard",
    StatusList: "Status List",
    TeamLeader: "Team Leaders",
    SubGroup: "Subgroup",
    TravelDriverList: "Driver Travels List",
    TravelDriversList: "Driver Travels List",
    ListArchive: "Archive of Trips",
    TotalCarAndTripCount: "Total Cars and Trips",
    MonthlyReport: "Monthly Quantity Report",
    MonthlyReportCarOwner: "Monthly Quantity Report - Car Owner",
    ListSentArchive: "Sent Archive",
    SentListArchive: "Sent Archive List",
    UsersNote: "User Notes",
    NoteModel: "Passenger Trip",
    Logout: "Exit",
    ManageFeedback: "Manage Feedback",
    SubmitFeedbackForm: "Submit Feedback",
    DriverList: "Drivers",
    DriverSmallNewList: "Drivers List (Summary) - New",
    CheckingActionsDriver: "Checking Driver Actions",
    CumulativeGroup: "Cumulative Group",
    CumulativeSales: "Cumulative Sales",
    CustomerBuyingProcess: "Customer Buying Process",
    ExpertSalesPercentage: "Expert Sales Percentage",
    PriceTrendsOverTime: "Price Trends Over Time",
    SalesOfCompanies: "Sales of Companies",
    SaleTarget: "Sales Target",
    SaleTargetCompare: "Sales Target Comparison",
    NumberOfComplaintsByGroupHead: "Number of Complaints by Group Head",
    reportCorrective: "Corrective Actions Report",
    NumberOfComplaintsBySalesExpert: "Number of Complaints by Sales Expert",
    InternalColuserTime: "Average Internal Closure Time",
    ExternalColuserTime: "Average External Closure Time",
    StatusOfComplaintsAtTheGroupLevel: "Status of Complaints at the Group Level",
    CustomerSatisfactionPercentage: "Customer Satisfaction Percentage",
    InitialResponseReport: "Initial Response Time Report",
    SalesByGroupHead: "Sales by Group Head",
    SalesVolumeByGroupHead: "Sales Volume by Group Head",
    ProductInProgress: "Product in Progress",
    ComplaintsByCategory: "Complaints by Category",
    BySalesManager: "By Sales Manager",
    SalesGroupTarget: "Cumulative Sales Target",
    SalesByArea: "Sales by Area",
    SeprateSales: "Detailed Sales by Expert",
    SalesByCustomerSeparate: "Sales by Customer",
    PriceByRemittance: "Price by Remittance",
    Targetting: "Targeting",
    ReportOfCustomerPurchaseProcess: "Customer Purchase Process Report",
    Bycomplaint: "Complaints by Solution",
    ReportPriceTrendsOverTime: "Price Trends Over Time Report",
    CustomerFeedbackList: "Customer Feedback List",
    Slogan: "Slogan",
    Logo: "Logo",
    TemplateSettings: "Template Settings",
    RemittanceRegistrationSettings: "Remittance Registration Settings",
    MonthlyExpenceReport: "Monthly Expense Report",

    // top bar menu
    website: "Website",
    cumulativeSales: "Cumulative Sales",
    referralRegistration: "Order Registration",
    feedbackRegistration: "Feedback Registration",
    productDuringOrdering: "Product During Ordering",
    headgroupRegistration: "Group Head Registration",
    lettersSection: "Letters Section",

    //openning group account page
    pastYears: "Past Years",
    openAccountForThisYear: "Open Account for Current Year (Purchase Volume)",

    //DefineSalesTargetCreate , DefineSalesTargetUpdate
    userCode: "User Code",
    updatedTime: "Updated Time",
    expertName: "Expert Name",
    productGroup: "Product Group",

    //common keys
    Factory: "Factory",
    Area: "Area",
    ProductGroup: "Product Group",
    size: "Size",
    Photo: "Photo",
    FactoryName: "Factory Name",
    ShamsiMoon: "Shamsi Moon",
    SalesExpert: "Sales Expert",
    Dimensions: "Dimensions",
    Plan: "Plan",
    degree: "Degree",
    color: "Color",
    FatherName: "Father's Name",
    BirthCertificateNumber: "Birth Certificate Number",
    description: "Description",
    descriptionOptional: "Description (Optional)",
    RegisterDate: "Registration Date",
    ResidentialStatus: "Residential Status",
    BlockStatus: "Block Status",
    numberOfTsargetSheets: "Number of Target Sheets",
    UserName: "Username",
    NameUser: "User's Name",
    ReferalRegistration: "Referral Registration",
    ReferalEdition: "Referral Edition",
    ReferralCount: "Referral Count",
    ReferralId: "Referral ID",
    Percent: "Percent",
    Title: "Title",

    //CreateUser,UpdateUser
    UserCode: "User Code",
    UpdateTime: "Update Time",
    CustomerName: "Customer Name",
    CustomerLastName: "Customer Last Name",
    GeographicalCoordinates: "Geographical Coordinates",
    AccessLevel: "Access Level",
    ParentUser: "Parent User",

    // CreateProducts, UpdateProducts
    ProductCode: "Product Code",
    ProductsCodeInFactory: "Product Code in Factory",
    TheMainFeatureOfTheProduct: "The Main Feature of the Product (Component)",
    PalletCalculationRate: "Pallet Calculation Rate",
    PalletPerFoil: "Pallets per Foil",
    BothPalleteCapacity: "Double Pallet Capacity",
    EastCapacity: "East Capacity (Pallet)",
    TrailerCapacity: "Trailer Capacity (Pallet)",
    SingleCapacity: "Single Capacity (Pallet)",
    Picture: "Picture",
    ProductUnit: "Product Unit",
    price: "Price",
    priceRialA: "Price A (Rial)",
    priceRialB: "Price B (Rial)",
    priceRialC: "Price C (Rial)",
    aFewSheetsOfAPallet: "A Few Sheets of a Pallet",

    //CreateCompany, UpdateCompany
    CompanyCode: "Factory Code",

    //CreateArea , UpdateArea
    CreateArea: "Create New Area",
    UpdateArea: "Update Area",
    SeeArea: "View Area",

    //CreateStatusList , UpdateStatusList
    CreateStatusList: "Create New Status",
    UpdateStatusList: "Update Status",

    //Dashboard
    ReferencesProvided: "References Provided",
    Blocked: "Blocked",

    //CreateProductComponents,UpdateProductComponents
    CreateProductComponents: "Create New Component",
    ProductComponentType: "Component Type",
    UpdateProductComponent: "Update Product Component",

    // ReportSales Filter
    SingleProductComponent: "Single Product Component",
    TwoProductComponent: "Two Product Component",
    ThreeProductComponent: "Three Product Component",
    FourProductComponent: "Four Product Component",

    //SepretSalesReportFilter
    FromDate: "From Date",
    ToDate: "To Date",
    FromCount: "From Count",
    ToCount: "To Count",
    FromYear: "From Year",
    ToYear: "To Year",
    FromDateRegisterTime: "From Registration Start Date",
    ToDateRegisterTime: "To Registration Start Date",
    FromDateDriverActive: "From Driver Active Date",
    ToDateDriverActive: "To Driver Active Date",
    Voter: "Voter",

    //salesVolumeByCustomerSeparateFilter
    StartPriceFrom: "Start Price From",
    StartPriceTo: "Start Price To",

    //RemittancePriceRangeFilter
    GroupHead: "Group Head",
    Color: "Color",
    TheMainCharacteristic: "The Main Characteristic",
    NameOrProductCode: "Name or Product Code",

    //Create Real Group Header
    BirthDate: "BirthDay",
    YearBirthDate: "Year of Birth",
    MarriedDate: "Marriage Date",
    PhoneNumber: "Phone Number",
    FinancialInterface: "Financial Interface",
    FinancialInterfaceNumber: "Financial Interface Number",
    WarehouseInterface: "Warehouse Interface",
    WarehouseInterfaceNumber: "Warehouse Interface Number",

    CustomerCodeInKimiaChoobCompany: "Customer Code in Kimia Choob Company",
    CustomerCodeInMomtazGolestanCompany: "Customer Code in Momtaz Golestan Company",
    CustomerCodeInKimiaRokeshCompany: "Customer Code in Kimia Rokesh Company",
    CustomerCodeInRashBinehCompany: "Customer Code in Rash Bineh Company",
    CustomerCodeInChoobSandalhCompany: "Customer Code in Choob Sandalh Company",
    UploadDocuments: "Upload Documents",
    MeliCard: "National ID Card",

    // create legal group header
    EconomicalNumber: "Economic Number",
    InsuranceCode: "Insurance Code",
    phone1: "Phone 1",
    Phone2: "Phone 2",
    CompanyPictureDocument: "Company Document Picture",

    //Trips
    DriverInstruction: "Driver instruction",
    PassangerPhoneNumber: "Passanger Phone Number",
    PickupPoint: "Pickup Point",
    PickupAddress: "Pickup address",
    Sum: "Sum",
    Deadline: "Deadline",
    Document: "Document",
    NameCarPlate: "Name/Car plate",
    InvoiceNo: "Invoice No",
    DueDate: "Due Date",
    TotalSum: "Total Sum",
    Period: "Period",
    OrderFleet: "Orders",
    Amount: "Amount",
    download: "Download",
    PaidOut: "Paid Out",
    GenerateReport: "Generate Report",
    DropOffPoint: "Drop Off Point",
    AddDestination: "Add Destination",
    BabySeat: "Baby Seat",
    Pet: "Pet",
    SelectAddress: "Select Address",
    //Add A Legal Buyer
    GroupHeadName: "Group Head Name",
    State: "State",
    CommitmentForm: "Commitment Form",
    NationalCardPic: "National ID Card Picture",
    GroupHeaderCommitmentForm: "Group Head Commitment Form",
    AttachedFile: "Attached File",
    AttachedFileUploaded: "Uploaded Attached Files",

    // diagram report of customer filter
    ProductName: "Product Name",
    Year: "Year",
    Size: "Size",
    TheMainFeatureOfTheProduct2: "Main Feature of the Product",

    //Product in progress filter
    ChooseStatusesInProgress: "Choose Statuses in Progress",

    // Main Settings Form
    SmsPanel: "SMS Panel",
    General: "General",
    LogoPic: "Logo Image",
    InputSlogan: "Input Slogan",
    InputPic: "Input Image",
    ThemeSettings: "Theme Settings",
    HeaderColor: "Header Color",
    BackgroundColor: "Background Color",
    MenuColor: "Menu Color",
    SubMenuColor: "Submenu Color",
    ValueAdded: "Value Added (Percentage)",
    DocumentsEmail: "Documents Email",
    Password: "Password",
    ErrorPassword: "Please enter the password again.",
    NewPassword: "New Password",
    RepeatNewPassword: "Repeat Password",
    OldPassword: "Old Password",
    ProfilePicture: "Profile Picture",
    AddProfilePicture: "Add Picture",
    ChangeProfile: "Edit Profile",
    ErrorPasswordConfirm: "Passwords do not match.",
    ErrorPasswordWeek: "Password is weak. (Minimum 6 characters including uppercase, lowercase, and numbers)",

    //feedbackform
    coderahgiri: "Tracking Code",
    darkhastkonandebazkhord: "Feedback Requester",
    namkarbarsabtkonandeform: "Form Submitter's Name",
    nammoshtarimostaghim: "Direct Customer Name",
    masoulpeigir: "Follow-up Responsible",
    ostansharh: "Province/City",
    moshtarighirmostaghim: "Indirect Customer",
    namekarshenasforosh: "Sales Expert Name",
    vazyat: "Status",
    zamandaghighsabtform: "Exact Form Submission Time",
    moshakhasatmahsoul: "Product Specifications",
    hajmmahsoulersalshode: "Volume of Sent Product",
    hajmmahsoulmayob: "Volume of Defective Product",
    tarikhvoghoemoshkel: "Date of Problem Occurrence",
    tarikhtahvil: "Delivery Date",
    mahalvoghoemoshkel: "Location of Problem Occurrence",
    shomarefaktor: "Invoice Number / Product Tracking Code / Pallet",
    dastehbandimozoeshekayat: "Complaint Subject Classification",
    sharh: "Description",
    filezamime: "Attachment File",
    rahhalpishnahadimoshtari: "Customer's Proposed Solution",
    tozihatrahhalpishnahadi: "Explanation of Customer's Proposed Solution",
    arzyabishekayatmoshtaridarsalehjari: "Evaluation of Customer Complaint in Current Year / Last Complaint Status",
    tedadkoleshekayat: "Total Number of Complaints",
    tedadshekayatbaz: "Number of Open Complaints",
    adadpichidegishekayat: "Complexity Index of Complaint",
    adadshedatshekayat: "Severity Index of Complaint",
    adadtekrarpazirishekayat: "Repetition Index of Complaint",
    hadaksarzamanpasokhgooei: "Maximum Response Time",
    vazyathadaksarzamanpasokhgooei: "Status of Maximum Response Time",
    eghdamatvakeshi: "Follow-up Actions",
    etmammohlatpasokhgooei: "Completion of Response Deadline",
    userhaeikehejazehmoshahedehformradarand: "Users Authorized to View the Form",
    namemoshtarimostaghim: "Direct Customer Name",

    // follow and response form
    coderadyabi: "Tracking Code",
    tabaghehbandishekayat: "Complaint Classification",
    tarikhtakmilformpeigiri: "Follow-up Form Completion Date",
    codekarkhanehmarbooteh: "Related Factory Code",
    datehbandimoshkel: "Problem Classification",
    sayer: "Other (with Example)",
    natijehbazdidyamozakereh: "Outcome of Customer Visit or Negotiation",
    halatshekayatvarednist: "Complaint State Not Entered",
    dastehbandielatrisheh: "Root Cause Classification (Customer-related)",
    sayerelal: "Other Reasons",
    zaman: "Time",
    halatshekayatvared: "Complaint State: Entered",
    sharehelatrisheh: "Description of Root Cause",
    RequestDescription: "Request Description",
    rahkarpishnahadi: "Proposed Solution",
    niazbehmojavezmodieamel: "Need for CEO Approval",
    motevaliejrayehrahkar: "Responsible for Solution Implementation",
    dastehbandirahkar: "Solution Classification",
    sayersastehbandi: "Other Classifications",
    tarikhtaeedmasoulfarayand: "Process Responsible Approval Date",
    taeedmasoulfarayand: "Process Responsible Approval",
    tarikhtaeedraeeskomiteh: "CFT Committee Responsible Approval Date",
    tarikhraeeskomiteh: "CFT Committee Chairman Date",
    tarikhtaeedmodiramelmarbooteh: "CEO Approval Manager Approval Date",
    taeedmodiramelmarbooteh: "CEO Approval Manager Approval",
    tarikhrahkarpishnahadiejrahodeh: "Date Proposed Solution Implemented and Informed to Customer",
    taeedrahkarpishnahadiejrashodehast: "Confirmation Proposed Solution Implemented and Informed to Customer",
    tarikheghdameheslahi: "Date of Effective Corrective Action",
    taeedeghdameeslahi: "Confirmation of Effective Corrective Action",
    tarikhshekayatmakhtoomehgardidehast: "Date Complaint Closed",
    taeedshekayatmakhtoomehgardidehast: "Confirmation Complaint Closed",
    tarikhnazarsanji: "Customer Satisfaction Survey Date",
    adadrezayatmoshtari: "Customer Satisfaction Score",

    //Survey Questionnaire form
    mizanerezayatazbahvetaomol: "Satisfaction Level with Interaction and Follow-up by Colleagues",
    mizanerezayatazzamanpasookh: "Satisfaction Level with Response Time",
    mizanerezayatazpasokherayehshodeh: "Satisfaction Level with Provided Response",
    mizanerezayatkoli: "Overall Satisfaction Level with Complaint Handling Process",

    //Corrective action form
    sharhadamentebaghmoshahedeh: "Description of Non-Conformity Observation",
    manbashenasaei: "Identification Source",
    saderkonandeheghdameslahi: "Corrective Action Issuer",
    semat: "Position",
    tarikh: "Date",
    motevalieghdamehejrayeheslahi: "Responsible for Implementing Corrective Action",
    elalrisheheyborozadamentebagh: "Root Causes of Non-Conformity",
    eghdamateslahimoredniaz: "Required Corrective Actions",
    sharheghdam: "Action Description",
    masouleghdam: "Action Responsible",
    mohlat: "Deadline",
    natijeh: "Result",
    afzoodanmoredehjadid: "Add New Case",
    eghdamatfooghasarbakhshboodehast: "The above actions have been effective",
    natijehpeigirieheghdamat: "Result of Follow-up on Implemented Actions",
    eghdamatehfooghasar: "The above actions were not effective, and a new corrective action with number ... has been issued",

    //send products form
    noevaiselenaghlieh: "Vehicle Type",
    namebarbari: "Freight Company Name",
    nameranandeh: "Driver Name",
    shomarehmobileranandeh: "Driver Mobile Number",
    shomarehbarnameh: "Waybill Number",
    tarikhbarnameh: "Waybill Date",
    addresssabtshodeh: "Address Registered in the Waybill",
    address1: "Address 1",
    addressjadid: "New Address",
    ersalpayamak: "Send SMS",

    /*** agent form ***/
    ListAgent: "List of Agents",
    RegisterAgent: "Add Agent",
    RegisterAgent2: "Agent Registration",
    AgentName: "Agent Name",
    EditionAgent: "Edit Agent",
    EditionRegisterDriver: "Edit Agent Registration for Car",
    EditionAccessCarOwner: "Change Agent's Access in Car Registration",
    SeenAgent: "View Agent",
    SeenAgentChanges: "View Agent Changes",
    ListCarOwner: "Fleets",
    RegisterCarOwner: "Add Car Owner",
    RegisterDiscount: "Add Discount",
    EditionDiscount: "Edit Discount",
    SeeDiscount: "See Discount",
    Fixed: "Fixed",
    Tax: "Tax",
    AverageRating: "Average rating",
    AverageTripDistance: "Average trip distance, km",
    TotalAcceptance: "Total acceptance, %",
    CompletionRate: "Completion rate, %",
    FinishRate: "Finish Rate",
    Utilization: "Utilization, %",
    OnlineHours: "Online hours",
    AcceptanceRate: "Acceptance rate, % (none optionalorders)",
    FinishedOrders: "Finished Orders",
    DriverScore: "Driver Score",
    CashRider: "Cash Rider",
    DriversFleet: "Drivers",
    Percentage: "Percentage",
    RegisterCarOwner2: "Car Owner Registration",
    EditionCarOwner: "Edit Car Owner",
    ShowCarOwner: "View Car Owner",
    EditionRegisterCarOwner: "Change Car Owner",
    CodeCarOwner: "Car Owner Code",
    CodeAgent: "Agent Code",
    CarOwner: "Car Owner",
    NameCarOwner: "Car Owner Name",
    Repeat: "Password Again",
    Name: "Name",
    first_name: "Name",
    Family: "Family Name",
    SurName: "Last Name",
    PersonalCode: "ID Card Number / Passport number",
    PersonalCardPicture: " ID Card Picture  / Passport number",
    BirthDay: "BirthDay",
    Phone: "Mobile Number",
    mobile: "Mobile Number",
    ValidationCode: "Verification Code",
    StaticPhone: "Landline Number",
    Whatsapp: "WhatsApp Number",
    Address: "Address",
    CompanyAddress: "Company Address",
    PostalCode: "Postal Code",
    CompanyRegisterNumber: "Company Register Number",
    OtherDocuments: "Other Documents",
    IpPrivate: "Private IP",
    ResendCode: "Resend Code",
    sendCode: "Send Code",

    /**** lists ****/
    EditCarOwnerBySelf: "Edit Car Owner",
    CarsList: "Cars",
    RPH: "RPH",
    Vehicles: "Vehicles",
    Applications: "Applications",
    payouts: "Payouts",
    otherExpenses: "other expenses",
    Totalacceptance: "Total acceptance",
    EPHnet: "EPH(net)",
    EPHgross: "EPH(gross)",
    GrossEarning: "Gross Earning",
    Bonuses: "Bonuses",
    Tips: "Tips",
    CashIn: "Cash in",
    InAppGross: "In-App Gross",
    CashGross: "Cash gross",
    NetEarning: "Net Earning",
    FinishedTrip: "Finished Trip",
    CarsListFleet: "Fleet Car",
    DeletedCarsList: "Deleted Cars",
    RegisterCar: "Add Car",
    EditCar: "Edit Car",
    ShowCar: "View Car",
    PassengerTravelsList: "List of Passenger Travels",
    DriverTravelsList: "List of Driver Travels",
    TravelPoint: "List of Travel Points",
    DriversList: "List of Drivers",
    AddDriversList: "Add Driver",
    DriverChangesList: "Driver Changes List",
    DriverPlaqueList: "Connected Plaques List",
    DriverRejectList: "List of Driver Rejected Offers",
    EditDriversList: "Edit Driver",
    ShowDriversList: "View Driver",
    ActionDriversList: "Driver Actions List",
    UserNotes: "User Notes",
    ExactTime: "Exact Departure Time",
    ReachingTime: "Estimated Time of Arrival",
    DriverStopTime: "Total Driver Stops Time on Route",
    DriverStopList: "List of Driver Stops on Route",
    BackCard: "Back Card",
    FrontCard: "Front Card",

    /*** cars ***/
    EditionRegisterCar: "Edit Car Registration",
    RegisterCar2: "Register Car",
    CarOwnerFamily: "Car Owner's Last Name",
    CarName: "Car Name",
    UserPhoneNumber: "Driver's User Phone Number",
    SmsActiveCar: "Send daily activity SMS to car owner",
    ConfirmPassword: "Confirm Password",
    RepresentativeCarOwner: "Car Owner's Representative",
    UserPhone: "User Phone Number",
    SmsActiveCarDaily: "Send daily activity SMS to them with the right to view all reports and edit information",
    Iran: "Iran",
    PlaqueNumber: "Plaque Number",
    CarType: "Car Type",
    CarColor: "Car Color",
    CarModel: "Car Model",
    ShomareShsi: "Chassis Number",
    TravelCompany: "Travel Company",
    RateAdmin: "Admin Rating",
    YearConstruction: "Year of Construction",
    RateCar: "Car Rating",
    HistoryRegister: "Registration Date",
    numberOfShown: "Number of Display",
    StartDate: "Start Date",
    PhotoCarCards: "Car Card Photo",
    BackCarCard: "Back of the Card Photo",
    FrontCarCard: "Front of the Card Photo",
    PhotoCarAccording: "Car Photo According to Sample",
    PhotoCarSide: "Car Side Photo",
    PhotoCarOpposite: "Opposite Car Photo",
    PhotoCarInside: "Inside Car Photo",
    OptionalDocuments: "Optional Documents",
    PhotoDocuments: "Document File",
    CarWork: "Do you work with the car yourself or another driver?",
    EffectReport: "(Selecting the above affects financial reports)",
    Register: "Register",
    Update: "Update",
    carInformation: "Car Information",
    carInformationChanges: "Car Information Changes",
    changeCarOwnerInfo: "Change Car Owner Information",
    changeAccessDrivers: "Driver Access Changes",
    ActiveUser: "Active User in the Application",
    ActiveUserNotFound: "Active User Not Found in the Application",
    LastedLoginTime: "Last Login Time",
    LastedLoginPhoneNumber: "Last Logged-in Mobile Number",
    userPhoneCarOwner: "Car Owner's User Phone Number",
    myself: "Myself",
    otherDriver: "Other Driver",
    CarBrand: "Car Brand",
    carCode: "Car Code",
    Vin: "Vehicle Identification Number (VIN)",
    TaxiTransportLisence: "Taxi Transport Lisence",
    FleetUserName: "Fleet Username",
    FleetName: "Fleet Name",
    DriverUserName: "Driver Username",
    /**Drivers**/
    AuthCodeDriver: "Driver Authentication Code",
    RowOrId: "Driver Row or ID",
    AuthCode: "Authentication Code",
    DriverCode: "Driver Code",
    driverMobile: "Driver's Mobile Number",
    relationPhone: "Contact Number",

    /*** travel ***/
    Category: "Category",
    LicensePlate: "License Plate",
    SeeTravel: "View Travel",
    SeeTravelPassenger: "View Passenger Travel",
    CodeTravelPassenger: "Passenger Travel Code",
    CodeTravelDriver: "Driver Travel Code",
    CodeOffer: "Offer Code",
    DateRequestCar: "Car Request Date",
    TimeRequestCar: "Car Request Time",
    PassengerName: "Passenger Name",
    DriverName: "Driver Name",
    DriverPhone: "Driver's Phone Number",
    ScoreRange: "Score Range",
    Plaque: "License Plate",
    PlaqueOrigin: "License Plate - Area",
    Origin: "Origin",
    Destination: "Destination",
    Destination1: "Destination 1",
    Destinations: ["Destination 2", "Destination 3", "Destination 4", "Destination 5"],
    EndTimeTravel: "Arrival Time at Passenger Destination",
    DescriptionPassenger: "Passenger Description",
    DescriptionDriver: "Driver Description",
    TravelOptions: "Travel Options",
    ServiceType: "Service Type",
    ServiceType1: "Service Type",
    Income: "Income",
    TotalIncome: "Total Income (Toman)",
    IncomePassenger: "Passenger Payment",
    CommentsPassengerForDrivers: "Passenger's Comment about the Driver",
    CommentsPassengerForCar: "Passenger's Comment about the Car",
    CommentsPassenger: "Passenger Comment",

    /*** wallet ****/
    Wallet: "Income (Wallet)",
    Wallet1: "Wallet",
    /*** note users ***/
    RegisterNote: "Add Note",
    EditNote: "Edit Note",
    ShowNote: "View Note",
    labelNote: "Your Note",
    Note: "Note",

    /** notification **/
    SendGroup: "Send Group",
    sendOne: "Send One",

    /**** support ***/
    SupportRequest: "Support Request",
    AddRequestSupport: "Add Support Request",
    ShowRequestSupport: "Show Support Request",
    SupportChildren: "Support Children",
    id: "ID",
    drivers: "Drivers",
    passenger: "Passenger",
    addDrivers: "Add Drivers",
    ShowDrivers: "Show Drivers",
    LastName: "Last Name",
    last_name: "Last Name",
    cardNumber: "Card Number",
    FamilyNumber: "Family Number",
    HomeNumber: "Home Number",
    HomeAddress: "Home Address",
    HomeLocation: "Home Location",
    HomeLocationLat: "Latitude",
    HomeLocationLong: "Longitude",
    HomeAddressP: "Address (Street/Number/Unit)",
    StatusCard: "Card Activity",
    ShabaNumber: "Shaba Number",
    LandLin: "LandLin Phone",
    HesabName: "Account Holder Name",
    hesabName: "Account Holder Name",
    MForm: "Reference Form",
    MCodeForm: "Document ID for Reference Form",
    EditDrivers: "Edit Drivers",
    RegisterDocument: "Register Document",
    DriverCard: "Driver's License",
    RepresentativeNumber: "Representative Number",
    CarOwnerNameAndID: "Car Owner Name and ID",
    UploadNotarizedForm: "Upload Notarized Form",
    UploadNationalCard: "Upload National ID Card",
    Agent: "Agent",
    // phoneNumber: "Phone Number",
    dateOfRegistration: "Date Of Registeration",
    numberOfCars: "Number Of Cars",
    credit: "Credit",
    // status: "Status",
    // action: "Action",

    /**** section 2 *******/
    /**** admins *****/
    ManagementAdmins: "Users",
    ListAdmins: "List of Admins",
    Management: "Management",
    AddAdmin: "Add Admin",
    InternalUser: "Discount",
    AddInternalUser: "Add Discount",
    EditInternalUser: "Edit Discount",
    ShowInternalUser: "View Discount",
    DedicatedIP: "Dedicated IP",
    FleetChanges: "Fleet Changes",
    CarChanges: "Car Changes",
    PassengerChanges: "Passenger Changes",

    /**** companies ****/
    companiesList: "Companies List",
    AddCompany: "Add Company",
    EditCompany: "Edit Company",
    SeeCompany: "View Company",
    Companies: "Companies",
    companyName: "Company Name",
    ceoName: "CEO Name",
    ceoPhone: "CEO Mobile",
    // userInterfaceName: "نام رابط کاربری",
    // userInterfacePhone: "شماره تلفن رابط کاربری",
    userInterfaceName: "Interface Name",
    userInterfacePhone: "Interface Phone Number",
    PostCode: "Post Code",
    serviceFeature: "Service Features",
    companyPhone: "Company Phone Number",
    CarPhone: "Car Phone Number",
    notarizedDocument: "Notarized Document Number",
    notarizedDocumentFile: "Upload Notarized Document",
    confirmationTermsContract: "Confirmation of Contract Terms",
    confirmationTermsPic: "Picture of Notarized Contract Form",
    uploadCompanyPhoto: "Upload Company Photo",
    uploadedCompanyPhoto: "Company Photo",
    /**** origin and destination ***/
    OriginsAndDestinations: "Origins And Destinations",
    ListCitiesStations: "List of Cities and Stations",
    AddCitiesStations: "Add City and Station",
    EditCitiesStations: "Edit City and Station",
    SearchDescription: "Search Description",
    SearchName: "Search Name",
    Station: "Station",
    Location: "Location",
    Cities: "Cities",

    /**** routes ****/
    Routes: "Routes",
    Route: "Route",
    ListRoutes: "List of Routes",
    AddRoute: "Add Route",
    EditRoute: "Edit Route",
    SeeRoute: "See Route",
    long: "long",
    lat: "lat",
    NameAndPhone: "Name and Phone",
    CitiesOnWay: "Cities on the Way",

    /*** Inter-route fare formula items ***/
    InterRouteA: "Inter-route Price | A1",
    PriceModal: "Price Model",
    ListPriceModal: "Price Model",
    AddPriceModal: "Add Price Model",
    EditPriceModal: "Edit Price Model",
    SeePriceModal: "See Price Model",
    ConstantRate: "Constant Rate",
    KilometerRate: "Kilometer Rate",
    TotalRouteFateA: "Total Route Fare Coefficient - A",
    MinimumDifferenceBetweenB: "Minimum Difference Between Initial and Final Fare - B",
    MinimumFare: "Minimum Fare",
    TimeRate: "Time Rate (Minutes)",
    CarRating: "Car Rating",
    TypeService: "Service Type",
    TypeOfService: "Type of Services",
    CloseFareCoefficient: "Close Fare Coefficient",
    CloseFarePercent: "Close Fare Percentage",
    Toman: "Toman",
    Euro: "Euro",
    ForPassenger: "For Passenger",
    FromKM: "From (KM)",
    ToKM: "TO (KM)",
    BasePrice: "Base Price",
    PricePerKM: "Price Per KM",
    PricePerMin: "Price Per Min",

    /**** Fare increase for a specific hour ****/
    FareIncreaseRouteA2: "Fare Increase for Route - A2",
    FareIncreaseHour: "Price increase for specific hours",
    ListFareIncreaseHour: "List of Fare Increases For Specific Hours",
    AddFareIncreaseHour: "Add Price increase for specific hours",
    EditFareIncreaseHour: "Edit Price increase for specific hours",
    ShowFareIncreaseHour: "Show Price increase for specific hours",
    NamesCitiesOrigin: "Origin City Names",
    FromHour: "From Hour",
    ToHour: "To Hour",
    PercentageIncrease: "Percentage Increase",

    /**** The owner of her special track cars ***/
    OwnerSpecialTrackCars: "Owners of Special Route Cars",
    ListOwnerSpecialTrackCars: "List of Owners of Special Route Cars",
    PassengerCompaniesSpecificRoute: "Passenger Travel Companies for Specific Route",
    ListPassengerCompaniesSpecificRoute: "List of Passenger Travel Companies for Specific Route",
    /**** total cars and trip ****/
    TotalCarsAndTrip: "Total Number of Cars and Trips",
    ListTotalCarsAndTrip: "List of Total Cars and Trips",
    /*** Separate list of cars ***/
    /*** total travel and trip ***/
    ListTotalTravelAndTrips: "List Of Number Of Trips And Car Income",
    ListTotalTravelAndTripsDeleted: "The List of The Number Of Trips And Car Income Has Been Deleted",
    SeparateListCars: "Separation of cars",
    ListSeparateListCars: "Detailed vehicle list",

    /*** blocked admin list ***/
    BlockedAdmin: "Blocked",
    BlockedCarOwner: "Blocked - Car Owner",
    /**** Category Support ****/
    CategorySupport: "Support Category",
    ListCategorySupport: "List of Support Categories",
    AddCategorySupport: "Add Support Category",
    AddNotification: "Add Notification",
    EditCategorySupport: "Edit Support Category",
    type: "Type",

    /**** Commission coefficient list ***/
    ListCommissionCoefficient: "Commissions",
    commission: "Commissions",
    AddCommissionCoefficient: "Add Commission",
    EditCommissionCoefficient: "Edit Commission",
    SeeCommissionCoefficient: "View Commission",
    CommissionCoefficient: "Commission",
    CommissionRate: "Commission Rate",
    PercentCommissionDriver: "Driver Commission Percentage",

    /**** travel Items ***/
    TravelItems: "Travel Items",
    AddTravelItems: "Add Travel Items",
    PenaltyCancelingPassenger: "Driver and Passenger Penalty",
    AnyCasualTraveler: "Any Casual Traveler",
    PenaltyNotAppearingOrigin: "Penalty for Not Appearing at Origin",
    CancellationFeeForPassenger: "Cancellation Fee for Passenger",
    MinimumDriverCredit: "Minimum Driver Credit",

    /**** create message ****/
    SendSms: "Send SMS",
    CreateMessage: "Create Message",
    MessageText: "Message Text",
    SendToAllCarOwners: "Send to All Car Owners",
    SendToAllCars: "Send to All Cars",
    SendToAllAgent: "Send to All Agents",
    SendToAllDriver: "Send to All Drivers",
    SendToAllPassenger: "Send to All Passengers",
    SendToAllUsers: "Send to All Users",
    car: "Car",

    /*** user group ***/
    UserGroup: "User Groups",
    ListUserGroup: "List of User Groups",
    AddUserGroup: "Add User Group",
    EditUserGroup: "Edit User Group",
    ShowUserGroup: "View User Group",
    GroupName: "Group Name",

    /**** issues list ***/
    IssuesList: "Issues",
    AddIssue: "Add Issue",
    EditIssue: "Edit Issue",
    SeeIssue: "See Issue",
    Issue: "Issue",
    IssueDescription: "Issue Description",
    UserType: "User Type",
    TripStatus: "Trip Status",
    Parent: "Parent",
    ADMIN: "admin",
    USER: "user",
    DRIVER: "driver",
    PASSENGER: "passenger",
    CARRIER: "carrier",
    SUPERVISOR: "supervisor",
    Fleet: "fleet",
    next: "next",
    done: "done",
    current: "current",
    Deactive: "deactive",
    cancelled: "canceled",
    Unpaid: "unpaid",
    canceledDueToNonPayment: "canceled_due_to_non_payment",
    lookingForDriver: "looking_for_driver",
    driverNotFound: "driver_not_found",
    driverIsNearby: "driver_is_nearby",
    DriverArrived: "driver_arrived",
    Traveling: "traveling",
    nearDetention: "near_detention",
    tripEnded: "trip_ended",
    cancelledByDriver: "cancelled_by_driver",
    cancelledByPassenger: "cancelled_by_password",
    cancelledByAdmin: "cancelled_by_admin",

    /**** Ads list ***/
    AdsList: "Ads",
    AddAds: "Add Ads",
    Lat: "Lat",
    Long: "Long",
    CompanyLogo: "Company Logo",
    ViewLimitation: "View limitation",
    ExpireTime: "Expire Time",
    /***service list***/
    ServiceList: "Service",
    Luggages: "Luggages",
    Luggage: "Luggage",
    SmallLuggage: "Small Luggage",
    MediumLuggage: "Medium Luggage",
    LargLuggage: "Larg Luggage",
    Seat: "Seat",
    MinimumPrice: "Minimum Price",
    Icon: "Icon",
    /**** passenger list ***/
    ListPassenger: "Passengers",
    ListArchivePassenger: "Archive of Passenger Trips",
    AddPassenger: "Add Passenger",
    EditPassenger: "Edit Passenger",
    ShowPassenger: "View Passenger",
    DateFirstTrip: "Date of First Trip",
    ExpireDate: "Expiration Date",
    IntroducedNumber: "Number of Introductions",
    Validity: "Validity",
    InvitationCode: "Invitation Code",
    distance: "Distance",

    /**** tables ***/
    FullName: "Full Name",
    MobileNumber: "Mobile Number",
    MobileSupportNumber: "Support Contact Number",
    NationalCode: "National Code",
    Email: "Email",
    StartWorking: "Start Date of Collaboration",
    ForceTelephone: "Emergency Telephone",
    Company: "Company",
    IdNumber: "Id Number",
    Role: "Role",
    Roles: "Roles",
    AddRole: "Add Role",
    status: "Status",
    changePayoutPeriod: "change payout period",
    StatusTypes: "Status Types",
    statusCompany: "Company Status",
    AddPhoto: "Add Photo",
    limit: "Limited",
    AddedPhoto: "Uploaded Photo",
    UserParent: "User Parent",
    TimeStartCooperation: "Start Time of Collaboration",
    Month: "Month",
    Time: "Time",
    Driver: "Driver",
    driver: "driver",
    fleet: "fleet",
    admin: "admin",
    user: "user",
    FareReturn: "Fare Return",
    PassengerGifts: "Passenger Gifts",
    DriverGifts: "Driver Gifts",
    TravelFare: "Travel Fare",
    CasualTraveler: "Casual Travelers",
    MemberShipOfDrivers: "Drivers Membership",
    Passengers: "Passengers",
    MiddleOfTheRoad: "Middle Of The Road",
    Stations: "Stations",
    GoldenPass: "Golden Passengers",
    SilverPass: "Silver Passengers",
    BronzePass: "Bronze Passengers",
    GoldenDriver: "Golden Drivers",
    SilverDriver: "Silver Drivers",
    BronzeDriver: "Bronze Drivers",
    NewPass: "New Passengers",
    NewDrivers: "New Drivers",
    Chair: "Chair",
    ItemNotFound: "Item Not Found",
    ChaiReserved: "Reserved Chair",
    Accidental: "Accidental",
    PhoneReserve: "Phone Reserve",
    NegativeCredit: "Negative Credit",
    NegativeCreditPass: "Negative Credit Passenger",
    NegativeCreditDriver: "Negative Credit Driver",
    PermanentBlock: "Permanent Block",
    PermanentBlockPass: "Permanent Block Passenger",
    PermanentBlockDriver: "Permanent Block Driver",
    Reward: "Reward",
    CancelByPassenger: "Cancel By Passenger",
    CancelByDriver: "Cancel By Driver",
    NotPassInOrigin: "Passenger Not In Origin",
    NotDriverInOrigin: "Driver Not In Origin",
    Special: "Special",
    HalfSpecial: "Half Special",
    TravelCodeDriver: "Driver Travel Code",
    DriverCount: "Driver Count",
    Drivers: "Drivers",
    RidingPass: "Riding Passengers",
    RidingVipPass: "Riding VIP Passengers",
    BusPass: "Bus Passengers",
    BusVipPass: "Bus VIP Passengers",
    VanPass: "Van Passengers",
    VanVipPass: "Van VIP Passengers",
    MiniBus: "Mini Bus",
    MiddleOfTheRoadPass: "Middle Of The Road Passengers",
    StationsPass: "Stations Passengers",
    SystemBlockedDrivers: "System Blocked Drivers",
    AdminBlockedDrivers: "Admin Blocked Drivers",
    ReqBlockedDrivers: "Requested Blocked Drivers",
    SystemBlockedCars: "System Blocked Cars",
    AdminBlockedCars: "Admin Blocked Cars",
    ReqBlockedCars: "Requested Blocked Cars",
    SystemBlockedPassenger: "System Blocked Passengers",
    AdminBlockedPassenger: "Admin Blocked Passengers",
    ReqBlockedPassenger: "Requested Blocked Passengers",
    PassengerTripCount: "Passenger Trip Count",
    DriverTripCount: "Driver Trip Count",
    ChairsPassenger: "Chairs (Number of Passengers)",
    Crimes: "Crimes",

    Row: "Row",
    Capacity: "Capacity",
    StartTravelTime: "Start Travel Time",
    StartTravelPassengerTime: "Start Passenger Travel Time",
    StatusTime: "Status Time",
    TerminalFare: "Terminal Fare",
    TerminalFareRoute: "Terminal Fare Main Route",
    TotalFare: "Total Fare",
    Operations: "Operations",
    CarPlaque: "Car Plate",
    CarCode: "Car Code",
    AccountNumber: "Account Number",
    PriceRequest: "Requested Amount",
    TimePay: "Payment Date",

    /**** utils ****/
    Save: "Save",
    Submit: "Submit",
    SubmitLogin: "Login",
    SubmitLoginByEmail: "Enter Your Email",
    SubmitLoginByPhone: "Login with Mobile Number",
    SubmitLoginByUsername: "Login with Username",
    Select: "Select",
    Services: "Services",
    Service: "Service",
    AddFile: "Add File",
    deactive: "Deactive",
    active: "Active",
    daily: "daily",
    weekly: "weekly",
    monthly: "monthly",
    totalPrice: "Total Price",
    priceWithTax: "Price With Tax",
    uploadPhoto: "Upload Photo",
    uploadedPhoto: "Uploaded Photo",
    sendData: "Sending Data",
    ErrorData: "Date is not valid",
    ErrorYear: "Year is not valid",
    RegisterService: "Add Service",
    EditionService: "Edit Service",
    ShowService: "Show Service",

    /*** login ***/
    SuccessLogin: "Successful login",
    SuccessAction: "Action successful",

    /**orders**/
    AddTravel: "Add Travel",
    cancelTravels: "Cancelled Travels",
    TravelsPayments: "Travels Awaiting Payment",
    EndTravel: "Completed Travels",
    RunningTrips: "Ongoing Trips",
    excelExport: "Excel Export",
    penaltyType: "Penalty Type",
    penaltyHow: "Penalty By",
    travelCodePassenger: "Passenger Travel Code",
    statusActive: "Current Status",
    newStatus: "New Status",
    addOrders: "Add Orders",
    searchUser: "Search User",
    PassengerNumber: "Passenger Phone Number",
    passengerAddress: "Passenger Address",
    passengerCount: "Number of Passengers",
    timeSycle: "Time Interval",
    from: "From",
    to: "To",
    DestinationDescription: "Destination Description",
    DescriptionRe: "Requested Description",
    calculatePrice: "Calculate Price",
    cancelOrder: "Cancel and New",
    DestinationInfoTravel: "Destination Travel Information",
    OriginInfoTravel: "Origin Travel Information",
    Options: "Options",
    Bus: "Bus",
    BusVip: "VIP Bus",
    Couler: "Cooler",
    CloseService: "Door-to-door Service",
    HalfCloseService: "Half Door-to-door Service",
    NotCloseService: "Non Door-to-door Service",
    Riding: "Riding",
    RidingVip: "VIP Riding",
    Van: "Van",
    VanVip: "VIP Van",
    Mask: "Mask",
    FrontChair: "Front Seat",
    TwoChairs: "Two Adjacent Seats",
    Overload: "Overload",
    CarryLuggage: "Luggage Handling",
    ExteraLuggage: "Extra Luggage",
    EachOtherSeats: "Number of Adjacent Empty Seats",
    EmptySeatCertain: "Certain Empty Seat Count",
    EmptySeatUnCertain: "Uncertain Empty Seat Count",
    offerCapacity: "Offer Capacity",
    EmptySeat: "Empty Seats Count",
    Hurry: "Urgent",
    SelectedDriver: "Selected Driver",
    SendShipment: "Send Shipment",
    Animals: "Pets",
    StopTime: "Stop Time",
    ShowDetaTravel: "View Travel Details",
    TravelCode: "Travel Code",
    TravelPassengerCode: "Passenger Travel Code",
    UserNumber: "User Number",
    CarNumber: "Car Number",
    CodeUser: "User Code",
    PassengerInfo: "Passenger Information",
    DriverInfo: "Driver Information",
    CarInfo: "Car Information",
    TravelInfo: "Travel Information",
    DetailPassengerButton: "Passenger Button Details",
    Code: "Code",
    ChangeStatus: "Change Status To",
    DriverLocation: "Driver Location",
    PassengerLocation: "Passenger Location",
    PhoneInfo: "Phone Information",
    ip: "IP",
    AuthenticationCode: "Authentication Code",
    DriverId: "Driver ID",
    EditDriverTrip: "Edit Driver Trip",
    SeeDriverTrip: "View Driver Trip",

    /**Stopping time**/
    StoppingTime: "Stop Time Range",
    RegisterStoppingTime: "Add Stop Time Range",
    EditionStoppingTime: "Edit Stop Time Range",
    SeeStoppingTime: "View Stop Time Range",
    StoppingTimeInput1: "Cost for Door-to-door / Half Door-to-door Service",
    Add: "Add",
    Edit: "Edit",

    /** jarime **/

    RegisterFines: "Driver And Passenger Penalty",
    EditionFines: "Edit Driver and Passenger Penalty",
    minJarimeRoad: "Minimum Driver and Passenger Penalty",
    minJarimeRoad1: "Setting Minimum Driver Travel Fare by Driver",

    /*** user groups ***/
    UserGroups: "User Groups",

    /** reward setting **/
    RewardSetting: "Prize Settings",
    UserRank: "User Rankings",
    MonthlyBorozFrom: "From Bronze Six Months",
    MonthlyBorozTo: "To Bronze Six Months",
    MonthlySilverFrom: "From Silver Six Months",
    MonthlySilverTo: "To Silver Six Months",
    MonthlyGoldenFrom: "From Golden Six Months",
    MonthlyGoldenTo: "To Golden Six Months",
    BonusBoronzAmount: "Bronze Bonus Amount",
    BonusSilverAmount: "Silver Bonus Amount",
    BonusGoldenAmount: "Golden Bonus Amount",
    RentCeiling: "Calculation Fare Ceiling per Travel Order",

    //tickets
    SendTicket: "Send Ticket",
    ShowTicket: "View Ticket",
    SendTicketAgent: "Send Supervisor Ticket",
    SendTicketOrBack: "Submit Ticket / Refer Ticket",
    EditTicketOrBack: "Edit Ticket / Refer Ticket",
    SendTicketOrBackAgent: "Submit Ticket / Refer Supervisor Ticket",
    EditTicketOrBackAgent: "Edit Ticket / Refer Supervisor Ticket",
    darajehahamyat: "Priority Level",
    RolePerson: "Recipient Role",
    NamePerson: "Recipient Name",
    scoreType: "Score Type",
    CarScore: "Car Score",
    WriteFrom: "Copy from Date",
    WriteTo: "Copy until Date",
    SubmitFrom: "Submit from Date",
    SubmitTo: "Submit until Date",
    CreatorName: "Creator Name",
    Refer: "Refer",
    SeeFile: "View File",
    logTicket: "Ticket Status History",
    GreenRed: "Green / Red",
    TimeChange: "Status Change Time",
    ContactMobile: "Contact Mobile",
    Green: "Green",
    Red: "Red",
    Price: "Amount",
    Gold: "Golden",
    Silver: "Silver",
    Bronze: "Bronze",
    Portal: "Portal",
    Profile: "Profile",
    ShareScreen: "Share Screen",

    //secretariat
    Secretariat: "Secretariat",
    SecretariatCategoryList: "List of Secretariat Types and Subjects",
    AddSecretariatCategory: "Add Secretariat Subject",
    SeeSecretariatCategory: "View Secretariat Subject",
    EditSecretariatCategory: "Edit Secretariat Subject",
    SecretariatNameType: "Subject / Type Name",
    SecretariatParentNameType: "Parent Subject Name",
    TypeName: "Type Name",
    ListCategoriesDepartedSecretariat: "List of Secretariat Types and Subjects",
    ListDepartedSecretariat: "List of Outgoing Correspondences",
    AddDepartedSecretariat: "Register Outgoing Correspondence",
    EditDepartedSecretariat: "Edit Outgoing Correspondence",
    ShowDepartedSecretariat: "View Outgoing Correspondence",
    ListDepartedReceived: "List of Incoming Correspondences",
    AddDepartedReceived: "Register Incoming Correspondence",
    EditDepartedReceived: "Edit Incoming Correspondence",
    DepartedNumberReal: "Letter Number",
    DepartedType: "Type",
    DepartedTitle: "Subject",
    DepartedClient: "Recipient",
    DepartedNumber: "Reference to Letter Number",
    DepartedNumber1: "Letter Number",
    DepartedRelatedNumber1: "Reference Number",
    DepartedHomeNumber: "Secretariat Number",
    DepartedDescribeSmall: "Summary",
    DepartedDescription: "Description",
    DepartedDateFix: "Date of Preparation",
    DepartedFixer: "Preparer",
    DepartedAcceptable: "Acceptance of Secretariat Registration",
    DepartedDateSend: "Sending Date",
    DepartedDateReceive: "Receive Date",
    DepartedSender: "Sender",
    DepartedSenderType: "Sender (Typist)",
    DepartedReceiver: "Receiver",
    DepartedReceiverBy: "Received by",
    DepartedSendBy: "Sent by",
    DepartedPhoneClient: "Recipient Registration Number",
    DepartedPhoneCallClient: "Recipient Contact Number",
    SenderRegistrationNumber: "Sender Registration Number",
    DepartedPhoneSupport: "Support Contact Number",
    DepartedRefererTicketToContact: "Refer Ticket to Correspondent",
    DepartedPrivate: "Confidential",
    DepartedName: "Secretariat User Name",
    UserDeparted: "Secretariat User",
    SpecialRouteCarOwner: "Special Route Vehicle Owners",
    SpecialRouteCompany: "Special Route Passenger Transport Companies",
    TotalCarsTrips: "Total Number of Vehicles and Trips",
    SeeDetailsTrip: "View Trip Details",
    LikeSelected: "Selected",
    DepartedUploadedAttach: "Uploaded Documents",
    DepartedDownloadAttach: "Download File",

    /*** last change admin ***/
    LastChangeAdmin: "The Latest User Management Changes",
    LastChange: "Last Changes",
    RecentActivities: "Recent Activities",

    /*** change status title ***/
    ChangeOrder: "Order Code",

    /*** driver trip ***/
    InfoMobile: "Mobile Information",
    DriverLocationL: "Driver Location",
    ChangeStatusTo: "Change Status To",

    /**** setting ***/
    Setting_awardHistory_a1: "Awards and Medals Section - Driver App",
    Setting_title: "Login Page Title - Portal",
    HeaderTitleSettings: "Header Title",
    MenuColorSettings: "Menu Color",
    SubmenuColorSettings: "Submenu Color",
    BackgroundColorSettings: "Background Color",
    Setting_text: "Login Page Description - Portal",
    Setting_photoUrl: "Login Page Background - Portal",
    Setting_support_number: "Support Number",
    Setting_support_number_driver: "Driver Support Number",
    Setting_support_number_passenger: "Passenger Support Number",
    Setting_minimum_driver_credit: "Minimum Driver Credit for Trip Code",
    Setting_minimum_passenger_credit: "Minimum Passenger Credit",
    Setting_minimum_user_credit: "Minimum Portal User Credit",
    Setting_driver_trip_departure_time: "Allowed Time Difference for Driver Trip Start vs Server Time",
    Setting_driver_allowed_time_to_cancel_trip: "Allowed Time Duration for Driver to Cancel Trip without Penalty",
    Setting_non_withdrawal_credit: "Minimum Non-Withdrawable Credit",
    Setting_distance_between_driver_passenger: "Distance between Driver and Passenger Origin/Destination for Car Map in App",
    Setting_radius_find_point_on_portal_map: "Radius to Find Data or Desired Point on Portal Map",
    Setting_maximum_credit_charge_passenger: "Maximum Allowed Amount to Charge Passenger Wallet",
    Setting_maximum_credit_charge_user: "Maximum Allowed Amount to Charge Portal User Wallet",
    Setting_allowed_range_to_use_passengerAtOrigin_button: "Allowed Distance for Passenger from Trip Origin to Press 'I'm at Origin' Button",
    Setting_passenger_representer_commission: "Passenger Representative Commission",
    Setting_awardSetting_description: "Description of Awards Settings and History",
    Setting_support_email: "Support Email",
    Setting_status: "Monthly Membership Status of Driver in App",
    Setting_fee: "Monthly Membership Fee of Driver in App",
    Setting_user_x: "User-x",
    Setting_user_y: "User-y",
    Setting_passenger_representative_bonus: "Bonus for Passenger Representative per Trip",
    AbilityToPayInCash: "Ability to pay in cash",
    InvitedRider: "Invited (Rider)",
    InvitedDriver: "Invited (Driver)",
    InviterRider: "Invited (Rider)",
    InviterDriver: "Invited (Driver)",
    DefaultCommissionForCars: "Default commission for cars",
    ActivateOrderPhoneNumber: "Activate Order phone number",
    OrderPhoneNumberPerMinute: " Order phone number per minute",
    MinimumBalanceToRequestCashoutIs: "Minimum balance to request cashout is",
    DelayPenaltyPerSecond: "Delay penalty per second",
    PetPrice: "Pet price",
    BabySeatSetting: "Baby Seat",
    percentage: "%",
    /*** dashboard ***/
    Dashboard: "Dashboard",
    DashboardCarOwner: "Fleet Dashboard",
    DashboardDriver: "Driver Dashboard",
    DashboardCompany: "Passenger Company Dashboard",
    DashboardShield: "Safety Shield Operator Dashboard",
    DashboardTicketNotCheck: "Unresolved Tickets",
    DashboardBlocked: "Blocked",
    DashboardPointAgent: "Supervisor Points This Month",
    DashboardCountTripYesterday: "Number of Trips Yesterday",
    DashboardTripCancelDriverYesterday: "Driver Trip Cancellations Yesterday",
    DashboardWallet: "Wallet",
    DashboardTripCurrent: "Vehicles in Transit",
    DashboardCarBlocked: "Blocked Vehicles",
    DashboardIncomeOneMonth: "Total Income for the Last 30 Days",
    DashboardRequestPay: "Withdrawal Request",
    DashboardIncomeYou: "Your Income",
    DashboardAllItemsToday: "Total Items for Today",
    DashboardMyItems: "My Items",
    DashboardAgentScoreThisMonth: "Supervisor Points This Month",
    DashboardRedAlertMonth: "Red Alerts This Month",
    DashboardAllRedAlert: "All Red Alerts",
    DashboardPreviousTrip: "Previous Trips",
    DashboardTicketSearch: "Pending",
    DashboardTicketSearch1: "Checked",
    DashboardTicketSearch2: "Under Review",
    DashboardTicketSearch3: "Under Review/Important",
    DashboardTicketSearch4: "Checked/Important",

    /*** request pay ***/
    RequestPay: "Withdrawal Request from Wallet",
    SubmitRequest: "Submit Request",
    RequestPayDescription: "The above amount is eligible for settlement until today.",
    RequestPayDescription1: "You can withdraw from your wallet up to twice a week.",
    RequestPayAcceptPay: "Available Withdrawable Balance: ",
    GetPrice: "Withdrawal Amount",
    YourCredit: "Your Credit",

    /*** support history ***/
    SupportHistory: "Support Status History",
    SupportDescription: "Content",
    SupportPhone: "Mobile Number",
    SupportOperator: "Operator",
    SupportCode: "Request Code",
    SupportWarning: "Warning",
    SupportRed: "Red",
    SupportGreen: "Green",

    /*** offers ***/
    ListOffers: "List of Offers",
    ListOffersByCar: "List of Car Offers",
    Offers: "Offers",

    /*** score bags ***/
    ScoreBagInternalUser: "Internal Users' Points Wallet",
    ScoreBaglUser: "Users' Points Wallet",
    ScoreBagInternalUserAdmin: "Internal Users' Points Wallet - Admin",
    ScoreBagPoint: "Point Code",

    /*** pay list ***/
    payList: "Payment Request List",
    PayDeposited: "Deposited",
    PayCanceled: "Canceled",
    PayPending: "Pending",

    /*** safety shield ***/
    SafetyShieldList: "Safety Shield Request List",
    SafetyShield: "Safety Shield",
    SafetyShieldChangeStatus: "Change Status of Safety Shield Request",
    SafetyShieldChangeLocation: "Car Location",
    SafetyShieldStatus1: "Unchecked",
    SafetyShieldStatus2: "Under Review",
    SafetyShieldStatus3: "Handled",
    SafetyShieldStatus4: "Emergency Priorities",
    SafetyShieldStatus5: "Not Important",
    RequestNumber: "Request Number",

    /**** table sms ***/
    SmsList: "SMS",
    SmsListCarOwner: "SMS List - Car Owner",
    SmsText: "SMS Text",
    SendTime: "Send Date",
    SenderUser: "Sender User",
    ContactPhone: "Contact Phone",
    SmsDescription: "SMS Description",

    /*** dashboard ***/
    DashboardOperator: "Dashboard of vehicle registration operators",
    DashboardMyDone: "My items (completed)",

    /*** tables ***/
    RequestService: "Service Request",
    RateStart: "Start Rate",
    RateEnd: "End Rate",
    LastChanges: "Last Changes",
    See: "See",

    /*** status list ***/
    SupportStatus1: "Pending",
    SupportStatus2: "Under Review",
    SupportStatus3: "Under Review/Important",
    SupportStatus4: "Checked/Important",
    SupportStatus5: "Checked",
    SupportLabel1: "Support Number Copied!",

    TicketStatusLabel1: "Status: Pending",
    TicketStatusLabel2: "Status: Under Review",
    TicketStatusLabel3: "Here you see any existing explanation.",
    TicketLabel1: "Ticket Number Copied!",

    CarOwnerLabel: "Changes in any of the following items require written confirmation and attachment to this page",
    CarOwnerLabel1: "List all messages related to the application on the portal for me",
    CarOwnerLabel2: "All messages related to the application be sent to me",
    CarOwnerLabel3: "(If you drive yourself, you must register with two different mobile numbers as the car owner and driver)",
    SignUpCarOwner: "Car Owner Registration",

    AccessLabel1: "Change the contact phone number for passengers to call drivers",
    AccessLabel2: "Change the password for cars",
    AccessLabel3: "Change in the answer to the question, do you drive yourself or another driver?",
    AccessLabel4: "(Choosing the above options affects financial reports)",
    AccessLabel5: "Change in optional description section",

    LoginLabel: "Forget Password",
    ResetPassword: "Reset Password",
    SendEmail: "Send Email",
    SendSmsOTP: "Send SMS Code",
    SendSmsOTPRepeat: "Resend SMS Code",

    TripPassengerLabel1: "Explanation of trip cancellation by driver",
    TripPassengerLabel2: "Driver performance details",
    ValidityAccount: "Credit Balance Amount",
    MonthlyPointToPassenger: "Monthly App Points to Passengers",
    YearlyPointToPassenger: "Annual App Points to Passengers",
    MonthlyPointTo: "Monthly App Points to",
    YearlyPointTo: "Annual App Points to",
    TripCancel: "Cancel Trip",
    TripTime: "Trip Time",
    ListTrips: "Trips",
    SeeTripPage: "Show Trip",
    AddTripPage: "Add Trip",
    TripId: "Trip ID",
    Trip: "Trip",
    Logs: "Logs",
    Transaction: "Transaction",
    passengerName: "Passenger Name",
    forOthers: "For Others",
    ServiceName: "Service Name",
    AdminUser: "Admin User",

    Current: "Current - Completed",
    Next: "Next - Canceled by Admin",
    // Trips Offer Status
    cancelByAdmin: "Canceled by Admin",
    cancelNoDriver: "Canceled due to No Driver",
    cancelNoPassenger: "Canceled due to No Passenger",
    closeToDestination: "Near Destination",
    driverArrived: "Driver Arrived",
    driverFounded: "Driver Found",
    driverIsClose: "Driver Is Close",
    findForDriver: "Searching for Driver",
    passengerAtOrigin: "Passenger at Origin",
    traveling: "Traveling",
    unpaid: "Unpaid",
    waitForAcceptDriver: "Waiting for Driver Confirmation",
    waitForAcceptPassenger: "Waiting for Passenger Confirmation",
    lookingForDriverStauts: "Looking for Driver",
    doneTrips: "Done",
    cancelledByPassengerTrips: "Cancelled By Passenger",
    cancelledByDriverTrips: "Cancelled By Driver",
    driverNotFoundStauts: "Driver not Found",

    // Trips Offer Status

    LastSeasonRate: "Last Season Ranking",
    LastUpdateDriver: "Last Driver Update",
    DocumentStatus: "Document Status",
    SeeFormRegisterDriver: "See Driver Registration Form",
    CarsConnectToDriver: "Cars Connected to Driver",
    ListDriverTrips: "List of Driver Trips",
    SeePassengerComments: "See Passenger Comments",
    MonthlyRateApplication: "Monthly App Rating",
    LastSixMonthsRateApplication: "Last Six Months Rating",
    YearlyRateApplication: "Annual App Rating",
    Application: "(Application)",
    AccessTime: "Access Date",
    CancelAccess: "Cancel Access",
    SixMonthsFirst: "First Six Months",
    SixMonthsSecond: "Second Six Months",
    SelectHalfMonth: "Select Half-Year",
    ListDrivers: "List of Drivers",
    ListPointTravels: "List of Travel Points",
    SeePersonActiveInApplication: "See Active Person in Application",
    ListAccessDriver: "List of Driver Accesses",
    ChangePassword: "Change Password",
    AverageScoreLastMonth: "Average Score Last Month",
    Plate: "Plate",
    InsideCar: "Inside",
    VehicleRegistrationCertificate: "Vehicle Registration Certificate",
    BackVRC: "Back",
    FrontVRC: "Front",
    TimeDelete: "Deletion Date",
    IsOwner: "Are you the owner and driver of this car ?",
    DescriptionChange: "Description of Change",
    /***get image car***/
    FrontCar: "Front Car",
    BackCar: "Back Car",
    LeftCar: "Left Car",
    RightCar: "Right Car",
    IntoCar: "Into Car",
    CarCards: "Car Cards",
    VehicleImage: "Vehicle Image",
    Active: "Active",
    InActive: "In Active",
    /*** new ***/
    ListNotification: "Notifications",
    ShowNotification: "Show Notification",
    Admin: "Admin",
    DriverDescription: "Driver Description",
    DetailsNextStep: "Details of Next Steps",

    furtherDesc: "Further Description",
    mter300: "300 Meters",
    Passenger_satisfaction: "Passenger Satisfaction",
    AdminTime: "Admin Office Time Limit",

    delete: "Delete",
    IBAN: "IBAN Number",
    Iban: "Iban",
    notarizedDocumentID: "Notarized Form ID",

    OldDataDriver: "Old Driver Informations",
    changedDataDriver: "New Driver Informations",

    Cash: "Cash",
    InvoicesFleetToRiderDesc: "Invoices that have been issued from you to riders and from Pikpalvelu to you",
    InvoicesFleetPikpalveluDesc: "Invoices that have been issued from you to riders and from Pikpalvelu to you",
    PikpalveluToFleet: "Pikpalvelu to fleet",
    InvoicesPayoutsDesc: "Invoices that have been issued from you to riders and from  Pikpalvelu to you",

    IssueTitle: "Issue Title",
    // Offfer Status
    Send: "Send",
    acceptRequestDriver: "Accept Request Driver",
    expired: "Expired",
    Receive_offer: "Receive Offer",
    Canseled: "Canseled",
    rejected_driver_offer: "Rejected Driver Offer",

    CompanyCreditCard: "Company credit card",
    PersonalCreditCard: "Personal credit card ",
    HereYouCanDownloadOrderHistoryOfYourDrivers: "Here you can download order history of your drivers.",

    EditAdmin: "Edit Admin",
    DriverRangeForPenalizingPassengers: "Driver's range for penalizing passengers",
    cancelledByAdmin2: "Cancelled by Admin",

    leftAComment: "Left a comment.",
    UploadedFileIsTooLarge: "Uploaded file is too large",
    hold: "Hold",
    acceptCertainDriver: "Accept Certain Driver",
    acceptUnCertainDriver: "Accept Un Certain Driver",
    passengerConfirmation: "Passenger Confirmation",
    onWayPassenger: "On Way Passenger",
    rejectDriverRequest: "Reject Driver Request",
    passengerCancellation: "passengerCancellation",
    driverCancellation: "Driver Cancellation",
    cancelAfterHoldPassenger: "Cancel After Hold Passenger",
    cancelAfterHoldDriver: "Cancel After Hold Driver",
    expiredByPassenger: "Expired By Passenger",
    expiredByDriver: "Expired By Driver",
    expiredOffer: "Expired Offer",
    cart: "Cart",
    DropAddress: "Drop Address",
    online: "Online",
    issueImage: "Issue Image",
    issueNote: "Issue Note",
    earnings: "earnings",
    tip: "tip",
    endingBalance: "Ending balance",
    nextPayout: "Next payout",
    currentBalance: "Current Balance",
    search: "Search",
    followUp: "Follow Up",
    forwardToPolice: "Forward To Police",
    completed: "Completed",
    canceled: "Canceled",
    onTrip: "On Trip",
  },
});

export default strings;

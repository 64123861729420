import axiosInstance from "../HelperAxios/axios";
import { toast } from "react-toastify";

export const getList = async (setList, setCount, url) => {
  const response = await axiosInstance
    .get(`${url}`)
    .then((res) => {
      setList(res?.data?.data);
      setCount(res?.data?.data?.count);
    })
    .catch((error) => {
      console.log(error);
      if (error.response.status === 403) {
        toast.warning("Dear user, you do not have access to this section");
        const user = JSON.parse(localStorage.getItem("user-pikgo"));
        // if (user.role === "صاحب ماشین") {
        //   location.href = "https://portal.nikohamrah.com/dashboard/carowner";
        // } else if (user.role === "راننده خودرو") {
        //   location.href = "https://portal.nikohamrah.com/dashboard/driver";
        // } else {
        //   location.href = "https://portal.nikohamrah.com/dashboard/admin";
        // }
      } else if (error.response.status === 404) {
        toast.error(error.response?.data.message);
      } else {
        toast.error(error.response?.data.message);
      }
    });

  return response;
};
